<template>
  <div class="faq faq-list-container">
    <v-container class="relative">
      <page-title :page="page" />
      <v-row>
        <v-col cols="0" md="4" v-if="!$vuetify.breakpoint.mobile">
          <h3 class="faq-title">FILTRA LE FAQ PER:</h3>
          <faq-category />
        </v-col>
        <v-col
          cols="12"
          :md="$vuetify.breakpoint.mobile ? '12' : '8'"
          class="position-relative"
        >
          <div v-if="faqData">
            <v-expansion-panels class="faq-list" accordion flat>
              <v-expansion-panel
                v-for="item in faqData"
                :key="item.id"
                class="mb-1"
              >
                <v-expansion-panel-header class="faq-panel-header">
                  <h4 v-html="item.title.rendered" @click="clicked"></h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    v-html="item.content.rendered"
                    @click="clicked"
                    class="faq-panel-content"
                  ></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <v-speed-dial
            v-if="$vuetify.breakpoint.mobile"
            v-model="fab"
            :right="true"
            :bottom="true"
            absolute
            direction="left"
            transition="slide-y-reverse-transition"
            class="faq-filter"
          >
            <template v-slot:activator>
              <v-btn v-model="fab" color="primary" fab>
                <v-icon v-if="fab">
                  $close
                </v-icon>
                <v-icon v-else>
                  $menu
                </v-icon>
              </v-btn>
            </template>
            <v-card><FaqCategory /> </v-card>
          </v-speed-dial>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss">
.faq-list-container {
  .faq-title {
    font-size: 13px;
    color: $primary;
  }
  .position-relative {
    position: relative;
  }
  .faq-panel-header {
    border: 1px var(--v-primary-base) solid;
    padding: 13px !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 13px !important;
  }
  .faq-panel-content,
  .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
    color: var(--v-primary-base) !important;
  }
  .faq-panel-content {
    margin-top: 16px;
  }
  .faq-list {
    .v-expansion-panel-header {
      color: var(--v-primary-base) !important;
      font-weight: bold;
    }
  }
  .faq-filter {
    .v-speed-dial__list {
      min-width: 400px;
      left: initial;
      right: 0;
    }
  }
}
</style>
<script>
import FaqCategory from "@/components/wordpress/FaqCategory.vue";
import CMSService from "~/service/cmService";
import page from "~/mixins/page";
import clickHandler from "~/mixins/clickHandler";

import PageTitle from "@/components/wordpress/PageTitle.vue";
export default {
  name: "FaqList",
  components: { FaqCategory, PageTitle },
  data() {
    return {
      faqData: null,
      fab: false
    };
  },
  mixins: [page, clickHandler],
  async mounted() {
    await this.fetchFaqList(this.$route.params.categoryName);
    // this.fetchPage("faq");
    this.page = this.$route.params.page;
  },
  methods: {
    async fetchFaqList(categoryName) {
      let res = await CMSService.getCustomPostByFilters(
        "faq",
        [
          {
            filterName: "filter[categoria_faq]",
            filterValue: categoryName
          }
        ],
        100
      );

      this.faqData = res.data;
    },
    stripHTML(html) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;
      const decodedString = new DOMParser().parseFromString(
        tempDiv.innerHTML,
        "text/html"
      ).body.textContent;
      return decodedString.replace(/\n/g, " ");
    }
  },
  computed: {
    jsonldData() {
      let _this = this;
      return {
        "@context": "http://schema.org",
        "@type": "FAQPage",
        mainEntity: this.faqData.map(item => ({
          "@type": "Question",
          name: item.title.rendered,
          acceptedAnswer: {
            "@type": "Answer",
            text: _this.stripHTML(item.content.rendered)
          }
        }))
      };
    }
  },
  jsonld() {
    if (this.faqData) {
      return this.jsonldData;
    }
  },
  watch: {
    "$route.params.categoryName": async function(categoryName) {
      await this.fetchFaqList(categoryName);
    }
  }
};
</script>
